import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { Tabulator, TabEntry } from '../../../components/widgets/tabulator';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyAgregarPaginaFacturacion: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Agregar una pagina de facturación <br />
                <span className="small">(Facturación en línea)</span>
            </h2>
            <ImageFooted explanation={`La imagen superior muestra la pagina para emitir facturas desde tu tienda en línea. \n Esta página utiliza el diseño de tu plantilla automáticamente. Puede ser usada para facturar cualuquier tipo de pedidos
            desde creados en línea, presenciales por POS, manuales en Admin, etc.`}>
                <IMG_Shopify file={'paginaFact'} />
            </ImageFooted>
            <h3>
                Pasos para agregar página de auto-facturación
            </h3>
            <p>
                Para agregarla, será necesario crear una página adicional a tu tienda o agregar el contenido
                mencionado a continuación en una sección que ya exista.
            </p>
            <Tabulator>
                <TabEntry label="Paso 1" sublabel="Agregar pagina">
                    <ImageFooted explanation={`1. Desde tu página de Admin, accedemos a Tienda Online y posteriormente a Páginas.
Una vez dentro escogemos Agregar página.`}>
                        <IMG_Shopify file={'paginaAdd'} />
                    </ImageFooted>
                </TabEntry>
                <TabEntry label="Paso 2" sublabel="Editar contenido">
                    <ImageFooted explanation={`2. Dentro de Agregar página, vamos a seleccionar el botón con los íconos de <> (recuadro naranja), el cual tiene la leyenda de Mostrar HTML.
Puedes agregar texto antes de editar en HTML, aunque recomendamos no hacerlo si no estás familiarizado con HTML.`}>
                        <IMG_Shopify file={'paginaWrite'} />
                    </ImageFooted>
                </TabEntry>
                <TabEntry label="Paso 3" sublabel="Cambiar a HTML">
                    <ImageFooted explanation={`3. Una vez hayas seleccionado el botón mencionado, el diálogo del editor cambiará al mostrado a continuación, sin herramientas de edición de texto como antes.
Nota: Si no agregaste texto en el paso anterior, este campo se mostrará vacío.`}>
                        <IMG_Shopify file={'paginaHtml'} />
                    </ImageFooted>
                </TabEntry>
                <TabEntry label="Paso 4" sublabel="Agregar <div>">
                    <>
                        <ImageFooted explanation={`4. Una vez estés en esta sección vamos a agregar el siguiente bloque de texto.`}>
                            <IMG_Shopify file={'paginaHtmlEdit'} />
                        </ImageFooted>
                        <pre>
                            <code>
                                &lt;div <span className="error">id="fiscalpop"</span>&gt;&lt;/div&gt;
                        </code>
                        </pre>
                    </>
                </TabEntry>
                <TabEntry label="Paso 5" sublabel="Visualizar página">
                    <>
                        <ImageFooted explanation={`5. Una vez guardada la página daremos click en, Visualizar página para ver cómo quedó.`}>
                            <IMG_Shopify file={'paginaHtmlPreview'} />
                        </ImageFooted>
                        <ImageFooted explanation={`6. Listo! ya podemos empezar a facturar en línea las órdenes de nuestros clientes.`}>
                            <IMG_Shopify file={'paginaFact'} />
                        </ImageFooted>
                    </>
                </TabEntry>
            </Tabulator>
            <SectionNavigate previous={`/ecommerce/shopify/claves-default`} next={`/ecommerce/shopify/facturacion-en-checkout`}/>
        </div>
    </Layout>
)

export default ShopifyAgregarPaginaFacturacion;